import {
  ContestContent,
  ContestPositionMap,
  ContestSelection,
  LocalString,
  OptionContent
} from '@aion-dk/js-client/dist/lib/av_client/types';

interface ConferenceContest {
  id: number
  reference: string
  title: LocalString
  subtitle?: LocalString
  description?: LocalString
  question?: LocalString
  ruleSet: string
  resultMethod: string
  resultChart?: string
  seats?: number
  tieBreaker?: boolean
  consensusRequired?: boolean
  consensusThreshold?: number
  consensusDivisor?: number
  countUnvoicedVotesAsBlank?: boolean
  minimumVotes: number
  maximumVotes: number
  blankOption: "disabled" | "active_choice" | "implicit"
  options: OptionContent[]
  optionsCount: number
  disregardWeights?: boolean
  disregardBlankVotes?: boolean
  maxPiles: number
  searchForm?: boolean
  collapsable?: boolean
  collapseDefault?: boolean
  votesAllowedPerOption: number
  randomizeOptions?: boolean
  blankOptionColor?: string
  customRulesets?: string[]
  encoding: {
    codeSize: number
    maxSize: number
    cryptogramCount: number
  }
}

const formatContest = (contest: ConferenceContest): ContestContent & { optionsCount: number } => {
  let options: OptionContent[] = contest?.options?.filter((el: OptionContent) => el.parent === null);

  let formatted: ContestContent & { optionsCount: number } = {
    reference: contest.reference,
    title: contest.title,
    options: options,
    optionsCount: contest.optionsCount,
    customRulesets: contest.customRulesets,
    disregardVoterWeight: contest.disregardWeights,
    markingType: {
      votesAllowedPerOption: contest.votesAllowedPerOption,
      blankSubmission: contest.blankOption,
      minMarks: contest.minimumVotes,
      maxMarks: contest.maximumVotes,
      maxPiles: contest.maxPiles,
      voteVariation: contest.ruleSet,
      encoding: {
        codeSize: 1,
        maxSize: 1,
        cryptogramCount: 1
      }
    },
    resultType: {
      name: contest.resultMethod
    },
  };

  if (contest.subtitle) formatted.subtitle = contest.subtitle;
  if (contest.question) formatted.question = contest.question;
  if (contest.description) formatted.description = contest.description;
  if (contest.collapsable) formatted.collapsable = contest.collapsable;
  if (contest.collapseDefault) formatted.collapseDefault = contest.collapseDefault;
  if (contest.searchForm) formatted.searchForm = contest.searchForm;
  if (contest.randomizeOptions) formatted.randomizeOptions = contest.randomizeOptions;
  if (contest.blankOptionColor) formatted.blankOptionColor = contest.blankOptionColor;

  return formatted;
}


const orderContests = (contestsSelection: ContestSelection[], contestPositions: ContestPositionMap): ContestSelection[] => {
  return contestsSelection.sort((a, b) =>
    contestPositions[a.reference] - contestPositions[b.reference]
  );
}

const flattenOptions = (tree: OptionContent[]): OptionContent[] => {
  return tree.flatMap((option: OptionContent) => {
    if (option.children) return [option, ...flattenOptions(option.children)];
    return [option];
  });
}

export { formatContest, orderContests, flattenOptions };
export type { ConferenceContest };
